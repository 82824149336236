import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import Layout from "../../layout";
import RequireAuthComponent from "./AuthComponent";

// Layout as suspense fallback so that the layout remain the same between screen transitions and avoid screen flashes
const RouteWithLayout = ({
  path,
  name,
  exact,
  component: Component,
  CustomLayout,
  ...rest
}) => {
  const LayoutComponent = CustomLayout ? CustomLayout : Layout;
  return (
    <Suspense fallback={<LayoutComponent {...rest} loading={true} />}>
      <LayoutComponent screenName={name} {...rest}>
        <Route
          path={path}
          exact={exact}
          name={name}
          render={(props) => <Component {...props} />}
        />
      </LayoutComponent>
    </Suspense>
  );
};

const RouteRequireAuth = (props) => {
  return (
    <RequireAuthComponent>
      <RouteWithLayout {...props} />
    </RequireAuthComponent>
  );
};

const RouteComponent = (props) => {
  // Route that requires user to login case
  if (props.requireAuth) {
    return <RouteRequireAuth {...props} />;
  }
  // Normal case
  return <RouteWithLayout {...props} />;
};

export default RouteComponent;
