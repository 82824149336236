export const ROUTE_PATH = {
  HOME: "/",
  LOGIN: "/login",
  EDIT_USER: "/edit-user",
  VERIFY: "/verify",
  TERM_AND_CONDITION: "/terms-and-conditions"
};

export const NOTIFY_TYPE = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning"
};

export const USER_STATUS = {
  UNVERIFIED: "unverified",
  VERIFIED: "verified",
  SUSPENDED: "suspended"
};

export const RECEIPT_CATEGORY= {
  CAR: "Car",
  TRAVEL: "Travel",
  OTHER:"Other",
  EDUCATION:"Education",
  HOME_OFFICE:"Home Office",
  OFFICE_SUPPLIES:"Office Supplies",
  CUSTOM_CATEGORY: 'Custom Category'
};