import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { ROUTE_PATH } from "../../constants";

const RequireAuthComponent = ({ children }) => {
  const { me } = useSelector((state) => state?.user);
  if (!me?.data?.email) {
    // Shows loading if login or refreshing token
    return <Redirect to={ROUTE_PATH.LOGIN}></Redirect>;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default RequireAuthComponent;
