import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="px-6 flex justify-between border-black border-solid border-t items-center h-16 text-center">
        <p className="font-normal text-base leading-5 text-left not-italic tracking-normal text-black">
          2022 ©H&R Block
        </p>
        <Link
          to="/#"
          className="font-normal text-base leading-5 text-left not-italic tracking-normal text-black cursor-pointer"
        >
          Terms & Conditions
        </Link>
      </div>
    </React.Fragment>
  );
};
export default Footer;
