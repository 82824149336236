import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    me: {},
    isNext: false,
    isPrevious: false,
    total: 0,
    users: [],
    user: {},
    taxYears: [],
    totalVerifiedUsers: 0,
    totalUnverifiedUsers: 0,
    totalUsers: 0
  },
  reducers: {
    authSuccess: (state) => {
      state.isLoggedIn = true;
    },
    getMeSuccess: (state, action) => {
      state.me = action?.payload?.user || {};
    },
    countUserSuccess: (state, action) => {
      state.totalUnverifiedUsers = action.payload.totalUnverifiedUsers;
      state.totalVerifiedUsers = action.payload.totalVerifiedUsers;
      state.totalUsers = action.payload.totalUsers
    },
    listUserSuccess: (state, action) => {
      state.users = [...action?.payload?.users] || [];
      state.total = action?.payload?.total || 0;
      state.isNext = action?.payload?.isNext || false;
      state.isPrevious = action?.payload?.isPrevious || false;
    },
    getUserSuccess: (state, action) => {
      state.user = action?.payload?.user || {};
    },
    getTaxYearsSuccess: (state, action) => {
      state.taxYears = action?.payload?.taxYears || [];
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  getMeSuccess,
  listUserSuccess,
  getUserSuccess,
  getTaxYearsSuccess,
  countUserSuccess
} = userSlice.actions;

export default userSlice.reducer;
