import { createSlice } from "@reduxjs/toolkit";

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    receiptCategoryUsersCount: {},
  },
  reducers: {
    countReceiptCategoryUsersSuccess: (state, action) => {
      state.receiptCategoryUsersCount = action.payload.labelUserCounts;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  countReceiptCategoryUsersSuccess,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
