import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { images } from "../../assets";
import InputIconFormik from "../../common/InputIconFormik/InputIconFormik";
import useLoginHook from "../../hooks/useLoginHook";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import { NAME_EVENTS } from "../../common/Loading/constant";
import EventEmitter from "../../common/Loading/index";
const Header = () => {
  const { handleLogout } = useLoginHook();
  const history = useHistory();
  const [keySearch, setKeySearch] = useState({ key: "" });
  useEffect(() => {
    const handleEventSearchUser = (e) => {
      setKeySearch({ ...keySearch, ...e });
    };
    const listener = EventEmitter.addListener(
      NAME_EVENTS.SEARCH_USER,
      handleEventSearchUser
    );
    return () => {
      listener.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="px-6 flex justify-between items-center h-24 text-center">
      <div className="flex justify-between">
        <img
          onClick={() => history.push(ROUTE_PATH.HOME)}
          src={images.logo}
          alt="logo"
          className="cursor-pointer"
        />
        <div className="h-16 w-0.5 opacity-20 mx-6 bg-black"></div>
        <div className="gird items-center">
          <p className="font-normal text-sm leading-5 text-left not-italic tracking-normal text-black">
            Receipt Tracker Application
          </p>
          <p className="font-black text-2xl leading-10 text-left not-italic tracking-tight text-black">
            Administer Users
          </p>
        </div>
      </div>

      <div className="text-right font-normal text-base leading-5 not-italic tracking-normal flex items-center">
        <Formik
          enableReinitialize={true}
          initialValues={{
            key: keySearch?.key
          }}
          onSubmit={(values) => {
            EventEmitter.emit(NAME_EVENTS.SEARCH_USER, values);
          }}
        >
          {({ values, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <InputIconFormik
                className="w-56"
                name="key"
                onChange={handleChange}
                value={values.key}
                placeholder="Search Users"
                isSearch="search"
                onSubmit={handleSubmit}
              />
            </Form>
          )}
        </Formik>
        <u
          onClick={() => handleLogout()}
          className="cursor-pointer ml-6 font-normal text-base leading-5 text-right not-italic tracking-normal"
        >
          Log out
        </u>
      </div>
    </div>
  );
};
export default Header;
