import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { getMeAPI } from "../APIs";
import { getMeSuccess } from "../features/users";

const useGetMeHook = () => {
  const dispatch = useDispatch();
  const getMeMutation = useMutation(getMeAPI, {
    onSuccess: (res) => {
      dispatch(getMeSuccess(res));
    },
    onError: (err) => {
      console.log("🧨 Get Me Error:", err);
    }
  });

  const handleGetMe = () => {
    getMeMutation.mutate();
  };

  return {
    isLoading: getMeMutation.isLoading,
    isSuccess: getMeMutation.isSuccess,
    isError: getMeMutation.isError,
    handleGetMe
  };
};

export default useGetMeHook;
