/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import useGetMeHook from "../hooks/useGetMeHook";
import Footer from "./components/Footer";
import Header from "./components/Header";
// import LoadingBar from "react-top-loading-bar";
// import { useSelector } from "react-redux";
import { ROUTE_PATH } from "../constants";

const Layout = ({
  hasHeader = true,
  hasFooter = true,
  children,
  ignoreLayout = false,
  requireAuth
}) => {
  const { handleGetMe, isError } = useGetMeHook();
  // const { isLoading } = useSelector((state) => state.common);
  // const loadingRef = useRef(null);

  useEffect(() => {
    if (requireAuth) {
      handleGetMe();
    }
  }, [requireAuth]);

  // useEffect(() => {
  //   if (isLoading) {
  //     loadingRef.current.staticStart();
  //   } else {
  //     loadingRef.current.complete();
  //   }
  // }, [isLoading]);

  if (ignoreLayout) {
    return <div>{children}</div>;
  }

  return (
    <React.Fragment>
      {/* <LoadingBar height={4} color={"#66C620"} ref={loadingRef} /> */}
      {isError && <Redirect to={ROUTE_PATH.LOGIN}></Redirect>}
      {hasHeader && <Header />}
      {children}
      {hasFooter && <Footer />}
    </React.Fragment>
  );
};

export default Layout;
