import { useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { loginAPI } from "../APIs";
import { ROUTE_PATH } from "../constants";
import useGetMeHook from "./useGetMeHook";

const useLoginHook = () => {
  const history = useHistory();
  const [error, setError] = useState();
  const { handleGetMe } = useGetMeHook();

  const loginMutation = useMutation(loginAPI, {
    onSuccess: (res) => {
      localStorage.setItem("token", res?.token);
      handleGetMe();
      setTimeout(() => {
        history.push(ROUTE_PATH.HOME);
      }, 1000);
    },
    onError: (err) => {
      const message = err?.data?.message || "Login fail";
      setError(message);
    }
  });

  const handleLogin = (values) => {
    loginMutation.mutate(values);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
    history.push(ROUTE_PATH.LOGIN);
  };

  return {
    isLoading: loginMutation.isLoading,
    isSuccess: loginMutation.isSuccess,
    handleLogin,
    error,
    setError,
    handleLogout
  };
};

export default useLoginHook;
