import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import RouteComponent from "./components/RouterComponent";
import routerConfig from "./routerConfig";

const WrapperRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routerConfig.map((route, idx) => {
          return <RouteComponent {...route} key={idx}></RouteComponent>;
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default WrapperRouter;
