import logo from "./images/logo.svg";

import visiblePassword from "./icons/visible-password.svg";
import invisiblePassword from "./icons/invisible-password.svg";
import notifySuccess from "./icons/notify-success.svg";
import close from "./icons/close.svg";
import search from "./icons/search.svg";
import arrowDown from "./icons/arrow-down.svg";
import arrowDownBlack from "./icons/arrow-down-black.svg";

export const LOGO_URL =
  "https://www.hrblock.com.au/Content/images/HRB-logo-dark.svg";
export const images = { logo };

export const icons = {
  visiblePassword,
  invisiblePassword,
  notifySuccess,
  close,
  search,
  arrowDown,
  arrowDownBlack
};
