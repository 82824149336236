import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    isLoading: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action?.payload || false;
    }
  }
});

export const { setLoading } = commonSlice.actions;

export default commonSlice.reducer;
