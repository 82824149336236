import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "../features/users";
import receiptReducer from "../features/receipts";
import categoryReducer from "../features/categories";
import commonReducer from "../features/common";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  storage: storage
};

const allReducer = combineReducers({
  user: userReducer,
  common: commonReducer,
  receipts: receiptReducer,
  categories: categoryReducer
});

const persistedReducer = persistReducer(persistConfig, allReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
});

export const persistor = persistStore(store);
export default store;
