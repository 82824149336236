import { useFormikContext } from "formik";
import React, { useState } from "react";
import { icons } from "../../assets";
import "./styles.scss";

const InputIconFormik = ({
  type = "text",
  name,
  id,
  label,
  placeholder,
  // iconSrc,
  className,
  isSearch,
  onSubmit,
  isShowText,
  ...rest
}) => {
  const { errors, values } = useFormikContext();
  const [visible, setVisible] = useState(false);
  return (
    <React.Fragment>
      <div className={className}>
        <div className="wrapper-input">
          <label
            htmlFor="email"
            className={values[`${name}`] ? `input-label-prefix` : `input-label`}
          >
            {label}
          </label>
          <input
            type={visible ? "text" : type}
            name={name}
            id={id}
            className="input"
            placeholder={placeholder}
            {...rest}
          />
          {type === "password" && isShowText === true && (
            <div
              className="absolute inset-y-0 right-1 flex items-center cursor-pointer"
              onClick={() => setVisible(!visible)}
            >
              <img
                alt="password"
                src={visible ? icons.visiblePassword : icons.invisiblePassword}
              />
            </div>
          )}
          {isSearch && (
            <div
              onClick={onSubmit}
              className="absolute inset-y-0 right-2 flex items-center cursor-pointer"
            >
              <img alt="icon-search" src={icons.search} />
            </div>
          )}
        </div>
        {errors[`${name}`] && (
          <div className="text-HRB_red">{errors[`${name}`]}</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default InputIconFormik;
